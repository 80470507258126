<template>
  <div>
    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-5-5">
        <div class="d-flex">
          <nav id="breadcrumbs" class="mb-3">
            <ul>
              <li>
                <a href="#"> <i class="uil-home-alt"></i> </a>
              </li>
              <li>{{ $t("institution.list.company") }}</li>
              <li>{{ $t("general.edit") }}</li>
            </ul>
          </nav>
        </div>

        <div class="card" v-if="loading">
          <default-loading></default-loading>
        </div>
        <template v-else>
          <div class="card">
            <div v-for="error in errorMessages" v-if="errorMessages" class="uk-alert-danger" uk-alert>
              <a class="uk-alert-close" uk-close></a>
              <p style="color: red">
                <i class="uil-exclamation-triangle"></i> {{ error }}
              </p>
            </div>
            <div v-if="successMessage" class="uk-alert-success" uk-alert>
              <a class="uk-alert-close" uk-close></a>
              <p style="color: green">
                <i class="uil-exclamation-triangle"></i>
                {{ $t("general.update_success") }}
              </p>
            </div>
          </div>
        </template>

        <div class="card row">
          <div class="uk-grid uk-child-width-1-2 uk-padding" uk-grid>
            <div>
              <h4>
                <i class="uil-user-circle"></i>
                {{ $t("institution.information") }}
              </h4>
              <div class="form-group row mb-3">
                <label class="col-md-12 col-form-label" for="filename">{{ $t("institution.avatar")
                  }}<span class="required">*</span></label>
                <div class="col-md-12">
                  <div class="position-relative d-flex justify-center justify-content-center align-items-center">
                    <img class="logoarea rounded img-thumbnail mb-2" :src="item.logo_link" alt="" />
                    <input type="file" id="logo" accept="image/*" class="d-none" ref="logo" name="filename"
                      @change="processFile($event)" />
                    <button type="button" class="btn btn-sm btn-outline-primary ml-2" @click="inputClick()">
                      {{ $t("general.image_change") }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group row mb-3">
                <label class="col-md-12 col-form-label" for="filename">Login Ekranı Görseli</label>
                <div class="col-md-12">
                  <div class="position-relative d-flex justify-center justify-content-center align-items-center">
                    <img v-if="item.login_images" class="logoarea2 rounded img-thumbnail mb-2"
                      :src="item.login_images[this.secureLocalStorage('language')]" alt="" />
                    <img v-else="!item.login_link" class="logoarea2 rounded img-thumbnail mb-2"
                      src="@/assets/images/no-photo.png" alt="" />
                    <input type="file" id="loginLogo" accept="image/*" class="d-none" ref="loginLogo" name="filename"
                      @change="processFileLogin($event)" />
                    <button @click="inputLoginLogoClick()" type="button" class="btn btn-sm btn-outline-primary ml-2">
                      {{ $t('general.image_change') }}
                    </button>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12">
                  <label class="col-form-label" for="name">{{ $t("institution.list.company_name")
                    }}<span class="required">*</span></label>
                  <input type="text" class="form-control" id="name" name="name" placeholder="" v-model="item.name"
                    required="" />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12">
                  <label class="col-form-label" for="start_date">{{ $t("institution.contract_start_date")
                    }}<span class="required">*</span></label>
                  <input type="date" class="form-control" id="start_date" v-if="item.contract" name="start_date"
                    placeholder="" v-model="item.contract.start_date" required="" />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12">
                  <label class="col-form-label" for="end_date">{{ $t("institution.list.contract_expired_date")
                    }}<span class="required">*</span></label>
                  <input type="date" class="form-control" id="end_date" name="end_date" :min="item.contract.start_date"
                    placeholder="" v-if="item.contract" v-model="item.contract.end_date" required="" />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12">
                  <label class="col-form-label" for="domain">{{ $t("institution.domain_name")
                    }}<span class="required">*</span></label>
                  <input type="text" class="form-control" id="domain" name="domain" placeholder="" v-model="item.domain"
                    required="" />
                </div>
              </div>

              <div class="form-group row domain uk-first-column">
                <div class="col-md-12">
                  <label class="col-form-label">{{ $t("institution.list.alias_name")
                    }}<span class="required">*</span></label>
                  <div class="d-flex">
                    <input type="text" id="alias" name="alias" v-model="item.alias" placeholder="Alias"
                      class="form-control" />
                    <span>.softinya.com</span>
                  </div>
                </div>
              </div>

              <div class="form-group row" v-if="isAdmin">
                <div class="col-md-12">
                  <label class="col-form-label" for="domain">Anzera Eğitim Pini</label>
                  <input type="text" class="form-control" v-model="item.pin" placeholder="" required="" />
                </div>
              </div>
            </div>

            <div>
              <h4><i class="uil-books"></i> Satın Alınan Katalog Eğitimleri</h4>
              <div class="section-header pb-0 mb-4">
                <div class="section-header-left">
                  <form action="" @submit.prevent="applyFilter">
                    <input v-model="searchQuery" :placeholder="$t('home.Education_shearing')" class="mt-3" type="text"
                      @input="isTyping = true" />
                  </form>
                </div>
                <div class="section-header-right">
                  <select v-model="order_by" class="ml-3 mb-0" @change="applyFilter">
                    <option value="name">
                      {{ $t("filter.Filter_by_name") }}
                    </option>
                    <option value="training_type_id">
                      {{ $t("filter.Filter_by_category") }}
                    </option>
                    <option value="created_at">
                      {{ $t("filter.Filter_by_date") }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="uk-width-2-3@m" style="width: 100%">
                <default-loading v-if="catalog_loading"> </default-loading>
                <template v-else>
                  <div class="card rounded uk-card-hover uk-card-small">
                    <div class="p-3 d-flex align-items-center justify-content-between">
                      {{ items ? items.total : 0 }} Katalog Eğitimi
                      Görüntüleniyor
                      <input id="user-admin" v-model="training_purchase_registration" type="checkbox" value="1"
                        style="width: 13px; margin-right: 10px;margin-top: 10px;" />
                      Satın Alındı Olarak Yap


                      <!-- <button type=" button" class="btn btn-sm btn-soft-secondary mr-2"
                        @click="selectedPurchase(event)">
                      <i class="uil-square"></i>
                      
                      </button> -->
                      <button type="button" class="btn btn-sm btn-soft-secondary mr-2" @click="allUserToggle()">
                        <i class="uil-check-square"></i>
                        {{ selectAllTrainingText }}
                      </button>
                      <!-- <h5 class="mb-0"> {{ selected_type.name }}</h5> <span> {{
                        items ? items.total : 0
                      }} {{ $t('education.education') }}</span> -->
                    </div>
                    <template v-for="(subItem, index) in items.data" v-if="items && items.data && items.data.length">
                      <hr class="m-0" />
                      <div class="uk-grid-small p-4 position-relative" uk-grid>
                        <!-- <div v-if="subItem.self_registrable_trainings.length > 0" class="ribbon ribbon-top-left"
                          style="height:50%"><span>KİRALANDI</span></div> -->
                        <div class="row" style="margin-top: 4px; width: 100%">
                          <div class="col-md-2">
                            <input v-model="self_registrable_training_ids" :id="'notification_checkbox_' + subItem.id"
                              :value="subItem.id" style="width: 15px; margin-top: 38px" type="checkbox" />
                          </div>
                          <div class="col-md-5">
                            <img :src="subItem.cover_image_link" alt="" class="rounded" />
                          </div>
                          <div class="col-md-5">
                            <a>
                              <h5 class="mb-2">{{ subItem.name }}</h5>
                              <p class="mb-0 uk-text-small mt-3">
                                <span class="mr-3 bg-light p-2 mt-1">
                                  {{
                                    $t("education.section_count", {
                                      number: subItem.training_content_count,
                                    }) +
                                    " / " +
                                    $t("education.content_count", {
                                      number: subItem.training_content_count,
                                    })
                                  }}</span>
                              </p>
                              <p class="uk-text-small mb-2 mt-4">
                                {{ subItem.description }}
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                  <Pagination v-if="items && items.data && items.last_page !== 1" :page.sync="pageProxy"
                    :paginate="items">
                  </Pagination>
                </template>
              </div>
            </div>
          </div>

          <div class="uk-card-footer pt-0">
            <div class="float-right">
              <button @click="saveForm()" class="btn btn-primary btn-sm mt-2" type="button">
                <i class="uil-check"></i> {{ $t("general.save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import module, {
  BASE_URL,
  GET_ITEM,
  ITEM,
  MODULE_NAME,
  ERROR,
  UPDATE_ITEM,
} from "@/core/services/store/company.module";
import store from "@/core/services";
import $ from "jquery";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import Pagination from "@/view/components/dataTable/Pagination";
import { mapGetters } from "vuex";
import {
  GET_ITEMS,
  ITEMS,
  LOADING,
  DELETE_ITEM_BY_ID,
} from "@/core/services/store/REST.module";

export default {
  name: "InstitutionEdit",
  components: {
    DefaultLoading,
    Pagination,
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, module);
  },
  data() {
    return {
      training_purchase_registration: false,
      selectAllTrainingText: "Tümünü Seç",
      imageInput: null,
      successMessage: false,
      errorMessages: [],
      search: null,
      searchQuery: "",
      isTyping: false,
      searchResult: [],
      options: [0],
      kinds: [0],
      order_by: "name",
      sort: "desc",
      restUrl: "api/training",
      educationTypes: [],
      selected_type: {
        id: null,
        name: this.$t("education.all_categories"),
      },
      filters: {
        page: 1,
        search: "",
        training_type_id: 0,
        training_kind_id: 0,
        admin_page: true,
        can_show_self_registrable_trainings: true,
        company_id: "",
      },

      successMessage: null,
      errorMessages: [],
      selected_type_name: null,
      trainingCategoryUrl: "api/training-type",
      page: 1,
      self_registrable_training_ids: [],
      allTraining: false,
    };
  },
  computed: {
    ...mapGetters({
      items: ITEMS,
      catalog_loading: LOADING,
    }),
    item: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEM];
      },
      set(value) { },
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    loading() {
      return store.getters[MODULE_NAME + "/" + LOADING];
    },
    pageProxy: {
      set(value) {
        this.page = value;
        this.applyFilter();
      },
      get() {
        return this.page;
      },
    }
  },
  methods: {
    allUserToggle() {
      if (this.selectAllTrainingText === "Tümünü Seç") {
        this.items.data.forEach((training) => {
          let isSelfTraining = false
          this.self_registrable_training_ids.every((selfTraining) => {
            if (selfTraining === training.id) {
              isSelfTraining = true
              return true
            } else {
              return true
            }
          });
          if (!isSelfTraining)
            this.self_registrable_training_ids.push(training.id);
        });
        this.allTraining = true;
        this.selectAllTrainingText = "Tümünü Kaldır";
      } else {
        this.items.data.forEach((training) => {
          let isSelfTraining = false
          let selfIndex = 0;
          this.self_registrable_training_ids.every((selfTraining, index) => {
            if (selfTraining === training.id) {
              isSelfTraining = true
              selfIndex = index;
              return true
            } else {
              return true
            }
          });
          if (isSelfTraining)
            this.self_registrable_training_ids.splice(selfIndex, 1);
        });
        // this.items.data.forEach((training) =>
        //   this.self_registrable_training_ids.push(training.id)
        // );
        this.selectAllTrainingText = "Tümünü Seç";
        this.allTraining = false;
      }
    },
    applyFilter() {
      this.$set(this.filters, "page", this.page);
      // this.$set(this.filters, 'training_type_id', this.selected_type.id)
      if (!(this.options.length === 1 && this.options[0] === 0)) {
        this.$set(this.filters, "training_type_id[]", this.options);
      }
      if (!(this.kinds.length === 1 && this.kinds[0] === 0)) {
        this.$set(this.filters, "training_kind_id[]", this.kinds);
      }
      this.$set(this.filters, "search", this.search);
      this.$set(this.filters, "order_by", this.order_by);
      this.$store
        .dispatch(GET_ITEMS, {
          url: this.restUrl,
          acceptPromise: false,
          showLoading: true,
          filters: this.filters,
        })
        .then((response) => {
          if (this.allTraining) {
            this.items.data.forEach((training) =>
              this.form["self_registrable_training_ids"].push(training.id)
            );
          } else if (response.data?.data) {
            response.data.data.forEach((training) => {
              training.self_registrable_trainings.forEach((data) => {
                if (
                  Number(data.pivot.company_id) ===
                  Number(this.filters.company_id)
                ) {
                  this.self_registrable_training_ids.push(
                    data.pivot.training_id
                  );
                }
              });
            });
          }
        });
    },
    saveForm() {
      let item = this.item;
      this.errorMessages = [];
      this.scrollToTop();
      if (item.name.length === 0) {
        this.errorMessages.push(this.$t("institution.required.name"));
        return false;
      }
      if (item.domain.length === 0) {
        this.errorMessages.push(this.$t("institution.required.domain"));
        return false;
      }
      if (item.alias.length === 0) {
        this.errorMessages.push(this.$t("institution.required.alias"));
        return false;
      }
      if (item.contract.end_date.length === 0) {
        this.errorMessages.push(this.$t("institution.required.end_date"));
        return false;
      }

      let formData = new FormData();
      formData.append("avatar", this.$refs.loginLogo.files[0]);
      formData.append("name", item.name);
      formData.append("domain", item.domain);
      formData.append("alias", item.alias);
      formData.append("start_date", item.contract.start_date);
      formData.append("end_date", item.contract.end_date);
      formData.append("company", item.id);
      formData.append("pin", item.pin);
      formData.append("training_purchase_registration", this.training_purchase_registration ? 1 : 0);
      this.$refs.loginLogo.files[0] ? formData.append('login_images[' + this.secureLocalStorage("language") + ']', form.loginLogo) :
      formData.append('login_images', []);
    
      // formData.append('login_images[' + this.secureLocalStorage("language") + ']', form.loginLogo);

      // if (this.allTraining) {
      //   formData.append("self_registrable_training_ids", "all");
      // } else {
      for (let i = 0; i < this.self_registrable_training_ids.length; i++) {
        formData.append(
          "self_registrable_training_ids[]",
          this.self_registrable_training_ids[i]
        );
      }
      // }
      store
        .dispatch(MODULE_NAME + "/" + UPDATE_ITEM, {
          url: BASE_URL + "/" + this.$route.params.id,
          contents: formData,
          returnType: "stateless",
          acceptPromise: true,
        })
        .then(() => {
          if (!this.error) {
            this.successMessage = true;
          } else this.errorMessages.push(this.error);
        });
    },
    processFile(event) {
      this.imageInput = event.target.files[0];
      if (!this.imageInput.type.includes("image/")) {
        this.errorMessages.push(this.$t("general.required_institution_logo"));
        return false;
      }
      let blob = URL.createObjectURL(this.imageInput);
      let imageHolder = $(".logoarea");
      imageHolder.attr("src", blob);
    },
    processFileLogin(event) {
      let imageInput = event.target.files[0];
      if (!imageInput.type.includes('image/')) {
        this.errorMessages.push(this.$t('general.required_institution_logo'));
        return false
      }
      let blob = URL.createObjectURL(imageInput);
      let imageHolder = $('.logoarea2');
      imageHolder.attr('src', blob);
    },
    inputClick() {
      $("#logo").click();
    },
    inputLoginLogoClick() {
      $('#loginLogo').click();
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  watch: {
    searchQuery: _.debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value) {
        this.searchUser(this.searchQuery);
      }
    },
  },
  mounted() {
    this.filters.company_id = this.$route.params.id;
    store.dispatch(MODULE_NAME + "/" + GET_ITEM, {
      url: BASE_URL + "/" + this.$route.params.id,
    });
    this.$store
      .dispatch(GET_ITEMS, {
        url: this.trainingCategoryUrl,
        acceptPromise: true,
      })
      .then((response) => {
        if (response.status) {
          self.educationTypes = response.data;
          self.educationTypes.unshift({
            id: 0,
            name: this.$t("education.all_categories"),
          });
        }
      });
    this.applyFilter();
  },
};
</script>

<style scoped>
.uk-alert {
  margin: 0;
}
</style>
